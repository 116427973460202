import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
window.bootstrap = bootstrap;
import '../../icon';
import './mhcorp.scss';
import '../../tab-deep-link';
import '../../deep-link';
import '../../navtabs.js';
import '../../collapse.js';
import '../../text-links.js';
import '../../video.js';
import '../../carousel.js';
import '../../blockquote.js';
import '../../accordion-tabs-bs5.js';
import '../../nested-modal.js';
import '../../stickyNav.js';
import '../../accordion.js';
import '../../anchorScrollHandler.js';
import '../../multinav.js';
import '../../purchase-options.js';
import '../../card-buttons.js';
import '../../scroll-container.js';
import '../../custom-select/custom-select.js';
import '../../button/hePdpStuInsBtnGroup.js';
import '../../popover.js';
import '../../globalnav.js';
import '../../micrositenav.js';
import '../../animations/container-zoom.js';
import '../../animations/aos/aos.js';
import '../../image-with-overlay.js';
import '../../interactive-image-map.js';
