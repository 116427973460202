if (document.readyState !== 'loading') {
  // delay the execution to make sure bootstrap is availiable
  setTimeout(initPopover, 500);
} else {
  document.addEventListener('DOMContentLoaded', initPopover);
}

function initPopover() {
  // We need to iterate over all elements with the 'data-bs-toggle="popover"' attribute
  // because we want to ensure that popovers are activated on page load for all relevant elements
  // that already exist in the DOM, even before any user interaction happens.
  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));

  popoverTriggerList.forEach((popoverTriggerEl) => {
    if (!popoverTriggerEl.dataset.listenerAdded) {
      addPopoverListeners(popoverTriggerEl);
    }
  });

  // This click event listener handles popovers that may be dynamically added to the DOM,
  // especially those inside shadow DOMs or newly added popover triggers. It ensures that
  // when the user clicks anywhere in the document, we check if the target element has
  // the 'data-bs-toggle="popover"' attribute, and if so, we initialize the popover for that element.
  document.addEventListener(
    'click',
    (event) => {
      const path = event.composedPath(); // Get event path through shadow and light DOM

      // Find the first element in the path with the popover attribute
      const popoverTriggerEl = path.find(
        (el) =>
          el instanceof Element && el.hasAttribute('data-bs-toggle') && el.getAttribute('data-bs-toggle') === 'popover'
      );

      if (popoverTriggerEl && !popoverTriggerEl.dataset.listenerAdded) {
        addPopoverListeners(popoverTriggerEl);
      }
    },
    true
  );
}

function addPopoverListeners(popoverTriggerEl) {
  let popover = window.bootstrap.Popover.getOrCreateInstance(popoverTriggerEl);

  const handleKeydown = (e) => {
    if (e.key === 'Escape') {
      popover.hide();
    }
  };

  const handleFocusOut = (e) => {
    if (!popoverTriggerEl.contains(e.relatedTarget)) {
      popover.hide();
    }
  };

  popoverTriggerEl.addEventListener('keydown', handleKeydown);
  popoverTriggerEl.addEventListener('focusout', handleFocusOut);
  popoverTriggerEl.addEventListener('shown.bs.popover', () => {
    togglePopoverAttributes(popoverTriggerEl, true);
  });

  popoverTriggerEl.addEventListener('hidden.bs.popover', () => {
    togglePopoverAttributes(popoverTriggerEl, false);
  });

  // Mark the popover as initialized to avoid redundant event bindings
  popoverTriggerEl.dataset.listenerAdded = 'true';
}

function togglePopoverAttributes(popoverTriggerEl, isOpen = false) {
  popoverTriggerEl.setAttribute('aria-expanded', String(isOpen));
  popoverTriggerEl.setAttribute('aria-haspopup', String(isOpen));
}
