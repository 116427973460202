class ImageOverlay {
  constructor(container) {
    this.container = container;
    this.openButtons = container.querySelectorAll('.open-overlay-btn');
    this.lastFocusedElement = null;
    this.init();
  }

  init() {
    this.container.querySelectorAll('.image-overlay-content').forEach((overlay) => {
      overlay.setAttribute('tabindex', '-1');
      overlay.addEventListener('keydown', this.handleEscapeKey.bind(this));
    });

    this.openButtons.forEach((button) => {
      const overlayContentId = button.getAttribute('aria-controls');
      const overlayContent = document.getElementById(overlayContentId);
      const closeButton = overlayContent?.querySelector('.close-overlay-btn');

      button.addEventListener('click', () => this.openOverlay(button, overlayContent));
      if (closeButton) {
        closeButton.addEventListener('click', () => this.closeOverlay(overlayContent));
      }
    });
  }

  handleEscapeKey(event) {
    if (event.key === 'Escape') {
      this.closeOverlay(event.currentTarget);
    }
  }

  openOverlay(button, overlayContent) {
    this.lastFocusedElement = button;
    this.toggleOverlayVisibility(overlayContent, true);
    this.handleOverlayTransition(overlayContent);
  }

  closeOverlay(overlayContent) {
    this.toggleOverlayVisibility(overlayContent, false);
    this.lastFocusedElement.focus();
  }

  handleOverlayTransition(overlayContent) {
    const closeButton = overlayContent.querySelector('.close-overlay-btn');
    const handleTransitionEnd = () => {
      if (closeButton) closeButton.focus();
      overlayContent.removeEventListener('transitionend', handleTransitionEnd);
    };
    overlayContent.addEventListener('transitionend', handleTransitionEnd);
  }

  toggleOverlayVisibility(overlayContent, isVisible) {
    overlayContent.classList.toggle('opacity-0', !isVisible);
    overlayContent.classList.toggle('invisible', !isVisible);
    overlayContent.setAttribute('aria-hidden', String(!isVisible));
    this.updateFocusableElements(overlayContent, isVisible);
    this.lastFocusedElement.setAttribute('aria-expanded', String(isVisible));
    this.lastFocusedElement.setAttribute('tabindex', isVisible ? '-1' : '0');
  }

  updateFocusableElements(overlayContent, isVisible) {
    const container = overlayContent.closest('.image-overlay-container')?.querySelector('.image-container');
    if (!container) return;

    const focusableElements = container.querySelectorAll('button, [href], input, select, textarea, [tabindex]');
    focusableElements.forEach((el) => {
      if (isVisible) {
        el.setAttribute('data-original-tabindex', el.getAttribute('tabindex') || '0');
        el.setAttribute('tabindex', '-1');
      } else {
        this.restoreOriginalTabindex(el);
      }
    });
  }

  restoreOriginalTabindex(element) {
    const originalTabindex = element.getAttribute('data-original-tabindex');
    if (originalTabindex !== null) {
      element.setAttribute('tabindex', originalTabindex);
      element.removeAttribute('data-original-tabindex');
    } else {
      element.removeAttribute('tabindex');
    }
  }
}

if (document.readyState !== 'loading') {
  document.querySelectorAll('.image-overlay-container').forEach((container) => new ImageOverlay(container));
} else {
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.image-overlay-container').forEach((container) => new ImageOverlay(container));
  });
}
