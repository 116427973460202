import AOS from 'aos';

/**
 * Expose AOS globally to enable manual refresh.
 * This is useful when new elements are added dynamically
 * or after a re-render.
 */
// Uncomment the line below to make AOS accessible globally
window.AOS = AOS;

// Call this method to refresh animations when needed
// AOS.refresh();

function initAOS() {
  const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

  AOS.init({
    disable: prefersReducedMotion.matches,
  });
}

// Initialize AOS
document.addEventListener('DOMContentLoaded', initAOS);
setTimeout(initAOS, 0);
