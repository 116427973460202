(function () {
  const interactiveImageMaps = document.querySelectorAll('.interactive-image-map');
  if (!interactiveImageMaps.length) return;

  interactiveImageMaps.forEach((interactiveImageMap) => {
    const outlines = interactiveImageMap.querySelectorAll('.outline');

    // Add keyboard interaction for paths
    outlines.forEach((element) => {
      element.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          element.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true }));
        }
      });
    });

    // Show/hide individual overlays on hover
    outlines.forEach((outline) => {
      outline.addEventListener('mouseenter', () => {
        outline.classList.add('hovered');
      });

      outline.addEventListener('mouseleave', () => {
        outline.classList.remove('hovered');
      });

      outline.addEventListener('focus', () => {
        outline.classList.add('hovered');
      });

      outline.addEventListener('blur', () => {
        outline.classList.remove('hovered');
      });
    });
  });
})();
